import React from 'react';
import './index.scss';
import App from './App';
import ModelProvider from "providers/ModelProvider";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ModelProvider>
        <App />
      </ModelProvider>
    </React.StrictMode>
  </BrowserRouter>);
