import React, {useContext,useEffect} from "react";
import Router from 'components/Router';
import { ModelContext } from "providers/ModelProvider";

function City({city}) {
  const { setAppState } = useContext(ModelContext);
  useEffect(()=>{
    setAppState(state=>{return{...state,city}});
  },[city,setAppState]);
  return <Router/>;
}

export default City;
