import React, { useState, createContext, useEffect, useCallback } from "react";
import Conf from 'Conf';
import { io } from "socket.io-client";
import axios from 'axios';

export const ModelContext = createContext({});
const socket = io(Conf.wsUrl);

const ModelProvider = ({children})=>{
  const [ appState,setAppState ] = useState({
    user:null,
    playgroundId:null,
    status:'wait',
    leaderBoard:[],
    userId:null,
    accessKey:null,
  });
  const [ sid,setSid ] = useState(null);
  const { city } = appState;
  useEffect(()=>{
    const handleConnect=() => {
      console.log(socket.id);
      setSid(socket.id);
      socket.io.engine.on("upgrade", () => {
        console.log('transport',socket.io.engine.transport.name);
      });
    };
    const handleDisconnect=() => {
      console.log(socket.id);
      setSid(null);
    };
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    return ()=>{
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    }
  },[setSid]);
  const post=useCallback((route,payload,cb=()=>{},responseType='json')=>{
    axios.post(Conf.apiUrl+route, {...payload, sid, city}, {responseType}).then((res)=>{
      console.log(res.data);
      cb(res.data.res);
    }).catch((error)=>{
      console.log(error);
    });
  },[sid,city]);
  useEffect(()=>{
    const handleMessage=(msg) => {
      console.log(msg);
      const { status, user, playgroundId, accessKey } = msg;
      if (status) {
        setAppState(state=>{return  {
          ...state,
          status,
        }});
      }
      if (user || user===null) {
        setAppState(state=>{return  {
          ...state,
          user,
        }});
      }
      if (playgroundId) {
        setAppState(state=>{return  {
          ...state,
          playgroundId,
        }});
      }
      if (accessKey) {
        setAppState(state=>{return  {
          ...state,
          accessKey,
        }});
      }
    };
    socket.on("message", handleMessage);
    return ()=>{
      socket.off("message", handleMessage);
    }
  },[setAppState,city]);
  useEffect(()=>{
    if(appState.city && sid && sid!==null) {
      console.log('ready to talk',appState.city,sid);
      setAppState(state=>{return{...state,status:'wait'}});
      post('room/hello',{clear:0},(data)=>{
        const {leaderBoard, accessKey} = data;
        if (leaderBoard && leaderBoard.length>0) setAppState(state=>{return{...state,leaderBoard}});
        if (accessKey) setAppState(state=>{return{...state,accessKey}});
      });
    }
  },[appState.city,sid,post,setAppState]);
  return (
        <ModelContext.Provider value={{appState,setAppState,sid,socket,post}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
