import React from "react";
import './App.scss';
import {
  Routes,
  Route,
} from "react-router-dom";
import City from 'components/City';
import CityDynamic from 'components/CityDynamic';
import NotFound from 'components/NotFound';

function App() {
  return <Routes>
    <Route path="/la/*" element={<City city="la"/>} />
    <Route path="/ist/*" element={<City city="ist"/>} />
    <Route path="/dub/*" element={<City city="dub"/>} />
    <Route path="/:city/*" element={<CityDynamic/>} />
    <Route path="*" element={<NotFound />} />
  </Routes>;
}

export default App;
